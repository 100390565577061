import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

const env = document.currentScript.dataset.env

if (env == "prod" || env == "stag") {
  Sentry.init({
    dsn: "https://dc9f7e06524442caa5c7dfee6122976a@o1077040.ingest.sentry.io/6079631",
    environment: env,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}